<template>
    <div id="page-about">
        <h3 class="page-title">About Me</h3>

        <div class="content">
            <div class="text">
                <p>
                    Hi, I'm <span itemProp="name">Chris Kulbacki</span>, <span itemProp="title">an entrepreneur, IT product development expert and
            Agile trainer</span> with nearly 30 years of professional experience.
                    I'm a <span itemProp="role">founder of <a href="https://scrumstar.com"  target="_blank">Scrum Star Academy</a></span>.
                </p>
                <p>
                    Early in my life I decided that the Earth is too big, diverse and wonderful to stay in one place, so I
                    traveled around the world from California to the Far East, living and working in 7 countries on 3
                    continents.
                </p>
                <p>
                    Before deciding that working in IT is my favorite thing to do, I tried a variety of other fields. I
                    worked
                    as a translator, a layout artist, a video editor, an audio technician and I taught at a university.
                </p>
                <p>
                    I'm very happy that I got to live in different countries and try my hand at a variety of professions. I
                    got
                    to know some fascinating cultures and interesting people, learn several languages and gain understanding
                    of
                    how business is conducted and projects are managed in different parts of the world.
                </p>
                <p>
                    Besides IT, I'm interested in astrophysics, humanism, social sciences, drawing and motorcycles.
                </p>
                <p>
                    If you'd like to get in touch with me, use the <router-link to="/contact">contact form</router-link> or contact me
                    on <a href="https://www.linkedin.com/in/chriskulbacki" target="_blank">LinkedIn</a>.
                </p>
                <p>
                    Thanks for stopping by! Hope you enjoy your visit!
                </p>
            </div>
            <div class="image">
                <img src="../assets/about-chris-kulbacki-1.jpg" alt="Chris Kulbacki" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },

        setup() {
        },

        mounted() {
           // console.log(this.$route)
            //console.log(this.ajaxUrl);

            console.log(process.env)
        },

        methods: {

        }
    }
</script>

<style lang="scss">
    #page-about {
        .content {
            display: flex;
            flex-wrap: wrap;

            .text {
                flex: 3;
                min-width: 400px;
                padding-bottom: 20px;
            }

            .image {
                text-align: center;
                min-width: 300px;
                flex: 2;
                padding-left: 30px;

                img {
                    width: 420px;
                    border: 3px solid #f5f5f5;
                }
            }
        }
    }
</style>